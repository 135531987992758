import React from "react";
import { CgLogOut } from "react-icons/cg";
import { useNavigate } from "react-router";
import { logout } from "../helper/auth";

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="up-nav">
      <button className="btn-2 logout" onClick={handleLogout}>
        Logout <CgLogOut />
      </button>
    </div>
  );
};

export default Header;
