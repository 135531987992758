import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { CgAddR, CgPushChevronLeftR } from "react-icons/cg";

const SignupPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const handleAdd = () => {
    if (cpassword === password && password && user) {
      const usern = [
        {
          user: user,
          password: password,
        },
      ];

      axios.post("http://localhost:8081/adduser", usern).then((response) => {
        alert(response.data.message);

        if (response.data.type === 0) {
          navigate("/");
        }
        if (response.data.type === 1) {
          setUser("");
        } else {
          console.log("Erro: " + response.data);
        }
      });
    } else {
      alert("Usuario ou Senha invalida!!");
    }
  };

  function BackButton() {
    const handleBack = () => {
      navigate("/");
    };

    return (
      <button className="btn-2" onClick={handleBack}>
        Voltar <CgPushChevronLeftR />
      </button>
    );
  }
  return (
    <div className="body">
      <section className="camada">
        <a href="/">
          <img
            src="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/iamticket-xEtLusgyWTtaKdCe6Jw0CyRacciA1a.png"
            alt="Camada"
          />
        </a>
      </section>

      <section className="group">
        <div className="group__rectangle-2">
          <div className="group__rectangle"></div>

          <h1 class="group__title">Crie uma conta para começarmos</h1>

          <input className="group__input" type="text" placeholder="Nome" />
          <input className="group__input" type="text" placeholder="CNPJ" />
          <input className="group__input" type="text" placeholder="E-mail" />
          <input className="group__input" type="password" placeholder="Senha" />

          <div className="group__link">
            <button class="group__btn" onClick={handleAdd}>
              Criar conta
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignupPage;
