import React from "react";
import { useNavigate } from "react-router-dom";
import { isValidToken, setUserToken } from "../../helper/auth";
import { useState } from "react";
import { IamTicketService } from "../../integrations/iam-ticket-service";

const LoginPage = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    if (user && password) {
      const iamTicketService = new IamTicketService();
      const { isAuth, token } = await iamTicketService.LogginUser(
        user,
        password
      );

      if (isAuth && isValidToken(token)) {
        setUserToken(token);
        navigate("/admin");
        return;
      }
    }
    alert("Usuário ou Senha inválidos");
  };

  return (
    <div className="body">
      <section className="camada">
        <img
          src="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/iamticket-xEtLusgyWTtaKdCe6Jw0CyRacciA1a.png"
          alt="Camada"
        />
      </section>

      <section className="group">
        <div className="group__rectangle-2">
          <div className="group__rectangle"></div>

          <h1 class="group__title">Olá! Seja bem vindo de volta</h1>

          <p class="group__text">Faça o seu login agora</p>

          <input
            className="group__input"
            type="text"
            placeholder="E-mail"
            value={user}
            onChange={(e) => {
              handleUserChange(e);
            }}
          />
          <input
            className="group__input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => {
              handlePasswordChange(e);
            }}
          />

          <div className="group__link">
            <a class="group__forgot__password" href="">
              Esqueceu sua senha?
            </a>

            <button class="group__btn" onClick={handleLogin}>
              Continuar
            </button>

            <a class="group__create__account" href="/signup">
              Cadastrar uma nova conta
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
