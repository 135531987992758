import React from "react";
import { CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";

import Header from "../header";
import { IamTicketService } from "../../integrations/iam-ticket-service";
import { getToken } from "../../helper/auth";

const AdminPage = () => {
  const navigate = useNavigate();
  const iamTicketService = new IamTicketService(getToken());

  const [itens, setItens] = useState([
    {
      _id: "",
      name: "",
      desc: "",
    },
  ]);

  useEffect(() => {
    async function fetchData(){
      const events = await iamTicketService.getAllEvents();
      setItens(events);
    }
    fetchData();
  }, []);

  const handleDetails1 = (itemId) => {
    navigate(`/item/${itemId}`);
  };

  return (
    <>
      <div className="App">
        <Header />
        <h1 className="header">Admin Page</h1>
        <div className="item-container">
          {itens.map((item) => (
            <div className="item-line">
              <div className="item-name">{item.name}</div>
              <button
                className="item-info"
                onClick={(e) => handleDetails1(item._id)}
              >
                <CgChevronRight />
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminPage;
