import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CgPushChevronLeftR } from "react-icons/cg";
import { useState, useEffect } from "react";
import Header from "../header";
import { IamTicketService } from "../../integrations/iam-ticket-service";
import { getToken } from "../../helper/auth";

const ItemDetailsPage = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const iamTicketService = new IamTicketService(getToken());

  function BackButton() {
    const handleBackButtonClick = () => {
      navigate(-1);
    };
    return (
      <button className="btn-back" onClick={handleBackButtonClick}>
        <CgPushChevronLeftR />
      </button>
    );
  }
  const [item, setItem] = useState([
    {
      _id: itemId ?? "",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      const eventData = await iamTicketService.getEventById(itemId);
      setItem(eventData);
    }
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="back-button-container">
        <BackButton />
      </div>
      <div className="item-details-container">
        <div className="item-head">
          <div>
            <h2>{item.name}</h2>
          </div>
        </div>
        <div className="item-body">
          <div>
            <h3>ID: {item._id}</h3>
          </div>
          <div>
            <p>Descrição: {item.description}</p>
            <p>Data de Inicio: {item.startDate}</p>
            <p>Data de conclusão: {item.endDate}</p>
            <p>
              Endereço:
              {`${item.address?.street}, ${item.address?.number}, ${item.address?.city} - ${item.address?.state} - ${item.address?.zip}`}
            </p>
            <p>CPNJ: {item.businessId}</p>
            <p>tickets: {item.tickets}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDetailsPage;
