import axios from "axios";
import { config } from "../configs/env";
export class IamTicketService {
    constructor(token = null) {
        this.url = `${config.integrations.iamTicketService.url}`;
        this.authorization = config.integrations.iamTicketService.authenticationToken
        this.token = token;
    }

    async LogginUser(email, password) {
        try {
            const userNew =
            {
                email,
                password,
            };

            const { data } = await axios.post(`${this.url}/login`, userNew, { headers: { 'Authorization': this.authorization } });
            const { isAuth, token } = data;

            return { isAuth, token };
        } catch (error) {
            console.log("Error while trying to log user", error);
            return { isAuth: false, token: null };
        }
    }

    async AddUser(user, password) {
        try {
            const userNew = [
                {
                    user,
                    password,
                },
            ];
            await axios.post(`${this.url}/adduser`, userNew, { headers: { 'Authorization': this.authorization } });

            return true;
        } catch (error) {
            console.log("Error while trying to add user", error);
            return false;
        }
    }

    async getAllEvents() {
        try {
            const { data } = await axios.get(`${this.url}/events`, { headers: { 'Authorization': this.authorization, 'token': this.token } });

            return data?.events;
        } catch (error) {
            console.log("Error while trying get events", error);
            return [];
        }
    }

    async getEventById(eventId) {
        try {
            const { data } = await axios.get(`${this.url}/event/${eventId}`, { headers: { 'Authorization': this.authorization, 'token': this.token } });

            return data?.event;
        } catch (error) {
            console.log("Error while trying get event by id", error);
            return {};
        }
    }
}